@import url('https://fonts.googleapis.com/css?family=Roboto|Scheherazade&display=swap');

$primary       : #2c6a33;
$secondary     : #C4C4C4;
$tertiary      : #F0F0F0;
$text          : #000000;
$text-secondary: #8D8C8C;
$bg-secondary  : #fff;
$bg            : #e9e8e6;

@mixin flexbox($direction, $align, $justify) {
  display: flex;
  flex-direction: $direction;
  text-align: $align;
  justify-content: $justify;
}

html, body, #root, .container {
  min-height: 100%;
}

html {
  position: relative;
  min-width: 200px;
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: $bg;
}

.container {
  // background-color: $bg-secondary;
  box-sizing: border-box;
  width: 100%;
  padding-left: 44px;
  padding-right: 44px;
  @include flexbox(column, center, space-between);
}

.artwork {
  box-sizing: border-box;
  width: 100%;
  padding-top: 100%;
  background-color: $bg;
  border-radius: 16px;
  margin: 32px auto 32px auto;
  background-image: url('./cactus.jpg');
  background-position: bottom right;
  background-repeat: no-repeat;
  border: 4px solid $bg-secondary;
  -webkit-box-shadow: 0px 4px 12px -2px rgba(0,0,0,0.3);
  -moz-box-shadow: 0px 4px 12px -2px rgba(0,0,0,0.3);
  box-shadow: 0px 4px 12px -2px rgba(0,0,0,0.3);
}

.metadata {
  @include flexbox(column, flex-start, space-between);
  text-align: left;
  margin-bottom: 32px;
}

.radio-name {
  color: $text;
  font-size: 24px;
  margin: 0 0 8px 0;
}

.address {
  color: $text-secondary;
  font-size: 16px;
  margin: 0 0 8px 0;
}

.freq {
  color: $text-secondary;
  font-size: 16px;
  margin: 0 0 8px 0;
  margin: 0;
}

.control {
  @include flexbox(column, center, space-between);
  margin-bottom: 32px;
}

.play {
  width: 72px;
  height: 72px;
  margin-left: auto;
  margin-right: auto;
  background-color: $primary;
  outline: none;
  border: none;
  border-radius: 50%;
  font-size: 56px;
  @include flexbox(row, center, space-between);

  img {
    width: 24px;
    margin: auto;
  }
}

.audio {
  display: none;
}