@import url(https://fonts.googleapis.com/css?family=Roboto|Scheherazade&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html,body,#root,.container{min-height:100%}html{position:relative;min-width:200px}body{font-family:'Roboto', sans-serif;background-color:#e9e8e6}.container{box-sizing:border-box;width:100%;padding-left:44px;padding-right:44px;display:flex;flex-direction:column;text-align:center;justify-content:space-between}.artwork{box-sizing:border-box;width:100%;padding-top:100%;background-color:#e9e8e6;border-radius:16px;margin:32px auto 32px auto;background-image:url(/static/media/cactus.8a946e07.jpg);background-position:bottom right;background-repeat:no-repeat;border:4px solid #fff;box-shadow:0px 4px 12px -2px rgba(0,0,0,0.3)}.metadata{display:flex;flex-direction:column;text-align:flex-start;justify-content:space-between;text-align:left;margin-bottom:32px}.radio-name{color:#000;font-size:24px;margin:0 0 8px 0}.address{color:#8D8C8C;font-size:16px;margin:0 0 8px 0}.freq{color:#8D8C8C;font-size:16px;margin:0 0 8px 0;margin:0}.control{display:flex;flex-direction:column;text-align:center;justify-content:space-between;margin-bottom:32px}.play{width:72px;height:72px;margin-left:auto;margin-right:auto;background-color:#2c6a33;outline:none;border:none;border-radius:50%;font-size:56px;display:flex;flex-direction:row;text-align:center;justify-content:space-between}.play img{width:24px;margin:auto}.audio{display:none}

